import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Formulario from "../components/Formulario"
import { StaticImage } from "gatsby-plugin-image"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";

export default function ReformasComercio() {

  gsap.registerPlugin(ScrollTrigger);
  const revealRefs = useRef([]);
  revealRefs.current = [];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let scrollTriggers = [];
    scrollTriggers = animateReveal(revealRefs.current);
    return () => {
      scrollTriggers.forEach((t) => t.kill(true));
    };
  }, []);

  const addToRevealRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  // end gsap


  const services = [
    {
      name: 'Revisión y análisis del espacio.',
      icon: 'icon-lista-de-verificacion',
    },
    {
      name: 'Diseño del presupuesto.',
      icon: 'icon-sketch',
    },
    {
      name: 'Aprobación del cliente.',
      icon: 'icon-home',
    },
    {
      name: 'Firma del contrato.',
      icon: 'icon-contract',
    },
    {
      name: 'Solicitud de permisos y ejecución de la obra',
      icon: 'icon-brick-wall',
    },
    {
      name: 'Entrega de las llaves del local.',
      icon: 'icon-keys',
    }
  ]


  const recomendaciones = [
    {
      name: 'Selecciona una empresa de reformas confiable y experta.',
      icon: 'icon-select',
    },
    {
      name: 'Establece los plazos de entrega y tu presupuesto.',
      icon: 'icon-calendar',
    },
    {
      name: 'Estudia con los expertos los aspectos estructurales y de servicios a renovar para evitar futuras sorpresas.',
      icon: 'icon-construir',
    }
  ]


  return (
    <Layout>
      <Helmet
        title="Empresa de reformas | Pide presupuesto ya"
        meta={[
          { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
        ]}
      />
      <div className="bg-[url('https://reformas.top/background/reformas-de-comercios.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
        <div className=" container flex flex-col xl:flex-row justify-between py-5">
          <div className=" lg:w-3/5  flex items-center justify-center 2xl:px-10">
            <h1
              className="text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl mb-10 text-white">
              Reformas de Comercios: oficinas, tiendas, bares y restaurantes <strong>en Madrid ciudad</strong>
            </h1>
          </div>
          <div className="lg:w-2/5">
            <Formulario />
          </div>
        </div>
      </div>
      <section className="container mt-20">
        <div className="max-w-4xl mx-auto mb-40">
          <p className=" text-text text-xl text-center gs_reveal gs_reveal_fromLeft">La imagen de un negocio suele ser un factor esencial en su éxito, y
            por eso, además de representar el espíritu de la empresa, debe hacer sentir a gusto a los clientes y a los
            trabajadores. Así que si eres propietario de un local que atienda directamente al público debes cuidar su
            acondicionamiento y todos los aspectos que lo hagan ver moderno y atractivo. Entonces, una reforma es lo que
            necesitas si quieres darle un impulso renovador a tu negocio, y lo bueno es que con nuestra empresa de
            reformas en ciudad podrás lograrlo sin problemas.</p>
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2">
            <StaticImage className="w-full object-cover rounded-3xl shadow-md" src="../images/reforma-de-local-comercial.webp" alt="Reformas de Cocinas" />
          </div>
          <div className="lg:w-1/2 lg:pl-10 flex flex-col justify-center">
            <h2 className="mt-6 text-5xl text-text font-semibold">¿Cuánto cuesta la reforma integral de un local?
            </h2>
            <p className="mt-6 text-text text-xl">
              La mejor forma de darle nueva vida a tu negocio es con una reforma. Más que un gasto de capital es una
              inversión que traerá consigo enormes beneficios financieros, ya que elevarás su valor y lo harás más
              atractivo.
            </p>
            <p className="mt-6 text-text text-xl">
              El precio de las reformas de locales en ciudad dependen de muchos factores, desde las
              dimensiones y el tipo de cambios hasta los materiales a emplear.
            </p>
          </div>
        </div>
        <div className="py-20">
          <h2 className="mt-6 text-5xl text-text font-bold text-center mb-20">Nuestro Método de Trabajo</h2>
          <div className="max-w-4xl text-center mx-auto">
            <p className="text-text text-xl">Nuestra empresa se destaca por ser una de las más confiables del sector, somos
              especialistas, en reformas de locales de todo tipo, así como en reformas de casas y pisos, puedes informarte
              en: <br /><strong>https://reformas.top/pisos/ciudad</strong>
            </p>
            <p> Nuestro método de trabajo es el siguiente:</p>

          </div>
          <div className="mt-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">

              {
                services.map((item, i) => {
                  return <div key={i} className="bg-white rounded-3xl shadow-2xl p-5">
                    <div className="flex flex-col items-center">
                      <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3">
                        <span ref={addToRevealRefs}
                          className={`ico-service block ${item.icon}`}></span></div>
                      <p className="text-2xl text-center"> {item.name}</p>
                    </div>
                  </div>
                })
              }

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div>
            <h2 className="mt-6 text-5xl text-text font-bold text-center mb-20">Nuestros servicios</h2>
          </div>
          <div className="flex flex-row items-center mb-20 w-11/12 mx-auto">
            <div className="basis-1/2">

              <StaticImage src="../images/reformas-de-oficinas.webp" alt="Reformas de Oficinas" />

            </div>
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 rounded-r-3xl p-10">
              <h2>Reformas de Oficinas</h2>
              <p>Las reformas de oficinas en Ciudad son parte de nuestra especialidad, sobre todo porque nos adaptamos a
                los plazos más estrictos y sabemos cómo adecuar un proyecto a las necesidades de cada empresa.</p>
            </div>
          </div>
          <div className="flex flex-row items-center mb-20 w-11/12 mx-auto">
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 rounded-l-3xl p-10">
              <h2>Reformas de Tiendas</h2>
              <p>Las tiendas predilectas de los clientes son las que cuentan con un ambiente cómodo y moderno, así que si
                tus ventas no son las que esperas, es posible que tus clientes no se sientan a gusto en tu local. Solicita
                una reforma a nuestra empresa y verás cómo tu tienda te dará los dividendos que deseas.</p>
            </div>
            <div className="basis-1/2">
              <StaticImage src="../images/reformas-de-bares.webp" alt="Reformas de Bares" />
            </div>
          </div>
          <div className="flex flex-row items-center mb-20 w-11/12 mx-auto">
            <div className="basis-1/2">
              <StaticImage src="../images/reformas-de-bares.webp" alt="Reformas de Bares" />
            </div>
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 rounded-r-3xl p-10">
              <h2>Reformas de Bares</h2>
              <p>Los bares son sitios que pasan de moda rápidamente y los estilos de diseño inciden en la afluencia de
                clientes. Es por eso que las reformas de bares en ciudad son muy solicitadas y nosotros conocemos las
                nuevas tendencias para que tengas el bar más moderno y concurrido de ciudad.</p>
            </div>
          </div>
          <div className="flex flex-row items-center mb-20 w-11/12 mx-auto">
            <div className="basis-1/2 bg-gray-bg bg-opacity-25 rounded-l-3xl p-10">
              <h2>Reformas de Restaurantes</h2>
              <p>Cuando se trata de reformas de restaurantes en ciudad, somos especialistas, al garantizar los plazos
                más
                reducidos y la mejor calidad de los acabados. Además, nos encargamos de las licencias de obra, el
                interiorismo y de mejorar la imagen corporativa de tu negocio.</p>
            </div>
            <div className="basis-1/2">
              <StaticImage src="../images/reformas-de-restaurantes.webp" alt="Reformas de Restaurantes" />
            </div>
          </div>
        </div>
        <div className=" mb-32 mt-24">
          <div className="container flex flex-row items-center w-11/12 mx-auto ">
            <div className="basis-1/2  p-10">
              <h2>Precio de la reforma de una tienda en Ciudad</h2>

            </div>
            <div className="basis-1/2">
              <p>Son muchos los aspectos a estudiar al momento de reformar una tienda al igual que las reformas de casas.
                Aunque en este caso se suman otros elementos, como los materiales publicitarios, escaparates, adecuación
                del
                espacio al estilo de la marca y al servicio que presta.</p>
            </div>
          </div>
          <div className="mt-20 container">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5">

              {
                recomendaciones.map((item, i) => {
                  return <div key={i} className="bg-white rounded-3xl shadow-2xl p-5">
                    <div className="flex flex-col items-center">
                      <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span ref={addToRevealRefs}
                        className={`ico-service block ${item.icon}`}></span>
                      </div>
                      <p className="text-xl text-center"> {item.name} </p>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}